import {Workbox} from 'workbox-window';

if ('serviceWorker' in navigator) {
    const wb = new Workbox('sw.js');
    wb.addEventListener('waiting', () => {
    	wb.addEventListener('controlling', () => {
       	    window.location.reload();
        });

        if (confirm('There is a new version of this software available, please upgrade now by clicking OK.') == true) {
            wb.messageSkipWaiting();
        }
    });
    wb.register();
}